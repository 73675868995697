<template>
  <div class="storage-wrap">
    <div class="bread-wrap">
      <i class="iconfont icon-shouji"></i
      ><span class="bread-title">云手机存储管理</span>
    </div>
    <div class="content">
      <table-bar @showupload="showupload" @getufilelist="getufilelist" />
      <com-table
        v-loading="tableloading"
        class="filelist"
        :headercellstyle="headercellstyle"
        :pagination="pagination"
        @pageSizeChange="pageSizeChange"
        @pageCurrentChange="pageCurrentChange"
        :columns="filestoragelist"
        :data="tabledata"
        tableheight="600"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          label="操作"
          min-width="250"
        >
          <template slot-scope="scope">
            <!-- <el-button
              :disabled="scope.row.FileType == 'FILE_LIVE' ? true : false"
              @click="showtongbu(scope.row)"
              type="primary"
              size="mini"
              :class="[
                'tongbubtn',
                { disabledbtn: scope.row.FileType == 'FILE_LIVE' },
              ]"
              >同步至云手机</el-button
            > -->
            <el-button
              @click="deletephone(scope.row)"
              type="primary"
              size="mini"
              class="deletbtn"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </com-table>
    </div>
    <up-load-model
      :showuploadmodel="showuploadmodel"
      @closeshowupload="closeshowupload"
      title="上传至存储空间"
      :region="region"
    />
    <synchro-model
      :showsynchromodel="showsynchromodel"
      @closeshowsynchro="closeshowsynchro"
      title="同步至云手机"
      :region="region"
      :row="row"
      :needfooter="true"
    />
    <delete-model
      :showdeletemodel="showdeletemodel"
      @closeshowdelete="closeshowdelete"
      title="删除文件"
      :region="region"
      :row="row"
      :needfooter="true"
    />
  </div>
</template>
<script>
import TableBar from "./TableBar/index.vue";
import ComTable from "@/components/Table/index.vue";
import UpLoadModel from "./Models/UpLoadModel.vue";
import SynchroModel from "./Models/SynchroModel.vue";
import DeleteModel from "./Models/DeleteModel.vue";
import { filestoragelist } from "@/utils/tableList";
import { getsession } from "@/utils/auth";
export default {
  data() {
    return {
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      tableloading: false,
      filestoragelist,
      pagination: {
        current: 1,
        size: 20,
        total: 0,
        offset: 0,
      },
      row: {},
      query: {},
      showuploadmodel: false,
      tabledata: [],
      region: "hk",
      showsynchromodel: false,
      showdeletemodel: false,
    };
  },
  components: {
    TableBar,
    ComTable,
    UpLoadModel,
    SynchroModel,
    DeleteModel,
  },
  activated() {
    this.getufilelist();
  },
  methods: {
    showtongbu(row) {
      this.row = row;
      this.showsynchromodel = !this.showsynchromodel;
    },
    closeshowsynchro() {
      this.showsynchromodel = !this.showsynchromodel;
    },
    deletephone(row) {
      this.row = row;
      this.showdeletemodel = !this.showdeletemodel;
    },
    closeshowdelete() {
      this.getufilelist();
      this.showdeletemodel = !this.showdeletemodel;
    },
    showupload() {
      this.showuploadmodel = !this.showuploadmodel;
    },
    closeshowupload() {
      this.getufilelist();
      this.showuploadmodel = !this.showuploadmodel;
    },
    getufilelist() {
      this.tableloading = true;
      let data = {
        StorageRegion: this.region,
        FileType: "",
        // FileType:"FILE_LIVE"
      };
      this.$store.dispatch("storage/getufilelist", { ...data }).then((res) => {
        if (res.RetCode == 0) {
          this.tableloading = false;
          this.tabledata = res.Files;
        }
      });
    },
    pageSizeChange(pagesize) {
      this.pagination.size = pagesize;
      // this.getufilelist()
    },
    pageCurrentChange(currentpage) {
      this.pagination.current = currentpage;
      // this.getufilelist()
    },
  },
  mounted() {
    this.query = {
      project_id: getsession("project_id"),
      // city_id: getsession("city_id"),
      product_type: getsession("product_type"),
    };
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>