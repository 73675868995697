<template>
  <models
    :showmodel="showsynchromodel"
    :title="title"
    :canceltext="canceltext"
    :confirmtext="confirmtext"
    :needfooter="needfooter"
    @confirm="confirmsynchro"
    @closemodel="closeshowsynchro"
    @open="open"
    class="synchromodel"
  >
    <p class="titlelabel">
      <span>选择云手机</span>
    </p>
    <tree
      ref="treefather"
      :props="props"
      :treedata="treedata"
      @treecheck="treecheck"
    />
    <p class="titlelabel">
      <span>目录名</span>
    </p>
    <el-input
      size="small"
      placeholder="请输入目录名"
      v-model="synchroForm.path"
    ></el-input>
    <com-table
      class="comtable"
      :data="fileList"
      :columns="columns"
      tableheight="300"
      tablesize="small"
      :headercellstyle="headercellstyle"
    />
  </models>
</template>
<script>
import Models from "../../../components/Models/index.vue";
import Tree from "../../../components/Tree/index.vue";
import ComTable from "../../../components/Table/index.vue";
import { getsession } from "../../../utils/auth";
export default {
  props: {
    showsynchromodel: {
      type: Boolean,
    },
    canceltext: {
      type: String,
    },
    confirmtext: {
      type: String,
    },
    needfooter: {
      type: Boolean,
    },
    title: {
      type: String,
    },
    region: {
      type: String,
    },
    row: {
      type: Object,
    },
  },
  watch: {
    region(val) {
      // this.gettreedata();
    },
  },
  data() {
    return {
      props: {
        label: function (data, node) {
          if (node.isLeaf) {
            let index = data.label.indexOf("-");
            let filtlable = data.label.substring(index + 1);
            let label = `${data.uphoneName}(${filtlable})`;
            return label;
          } else {
            return data.label;
          }
        },
        children: "children",
      },
      treedata: [],
      phonelist: [],
      query: {},
      synchroForm: {
        path: "/sdcard/Download",
      },
      timmer: null,
      fileList: [],
      columns: [
        {
          prop: "uphoneName",
          label: "云手机名称",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "phoneId",
          label: "云手机ID",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "FileName",
          label: "文件名",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
        {
          prop: "status",
          label: "同步状态",
          width: "120",
          align: "center",
          headerAlign: "center",
          selection: false,
          index: false,
          expand: false,
        },
      ],
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
    };
  },
  components: { Models, Tree, ComTable },
  methods: {
    open() {
      // this.fileList = [this.row];
    //   this.gettreedata();
    },
    confirmsynchro() {
      let filelist = this.fileList.filter((item, index) => {
        item.status = "正在同步...";
        return true;
      });
      this.fileList = filelist;
      let payload = {
        Action: "SyncUPhoneStorageFileToUPhones",
        FileId: this.row.FileId,
        ProjectId: this.query.project_id,
        StorageRegion: this.region,
        UPhoneIds: [],
        TargetPath: this.synchroForm.path,
      };
      this.phonelist.forEach((item, index) => {
        payload.UPhoneIds.push(item.phoneId);
      });
      this.$store
        .dispatch("storage/syncuphones", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.decribefilestatus(res.TaskId);
          } else {
            let filelist = this.fileList.filter((item, index) => {
              item.status = "同步失败";
              return true;
            });
            this.fileList = filelist;
            this.$message.error("同步云手机异常");
          }
        });
    },
    closeshowsynchro() {
      this.treedata = [];
      this.fileList = [];
      clearInterval(this.timmer);
      this.timmer = null;
      this.$emit("closeshowsynchro");
    },
    decribefilestatus(TaskId) {
      let payload = {
        Action: "DescribeUPhoneFileSyncStatus",
        ProjectId: this.query.project_id,
        UPhoneIds: [],
        TaskId,
      };
      this.phonelist.forEach((item, index) => {
        payload.UPhoneIds.push(item.phoneId);
      });
      this.timmer = setInterval(() => {
        this.$store
          .dispatch("storage/discribefilesstatus", { ...payload })
          .then((res) => {
            if (res.RetCode == 0 && res.Status !== null) {
              res.Status.forEach((item, index) => {
                this.fileList.forEach((fitem, findex) => {
                  if (item.UPhoneId == fitem.phoneId) {
                    if (item.Status == "SYNC_SUCCESS") {
                      fitem.status = "同步成功";
                    }
                    if (item.Status == "SYNC_FAILED") {
                      fitem.status = "同步失败";
                    }
                  }
                });
              });
            }
          });
      }, 5000);
    },
    treecheck() {
      var checkedlist = this.$refs.treefather.$refs.tree.getCheckedNodes(
        false,
        true
      );
      var oldphoneList = [...this.phonelist];
      var newphoneList = [];
      checkedlist.map((item, index) => {
        if (item.isLeaf == true) {
          newphoneList.push(item);
        }
      });
      this.phonelist = this.getfinnallist(oldphoneList, newphoneList);
      let filelist = this.phonelist.map((item, index) => {
        return {
          uphoneName: item.uphoneName,
          phoneId: item.phoneId,
          status: "等待中",
          ...this.row,
        };
      });
      console.log(filelist);
      this.fileList = filelist;
    },
    getfinnallist(oldlist, newlist) {
      let relist = [];
      let inlist = [];
      let finnallist = [];
      for (let i = 0; i < oldlist.length; i++) {
        let flag = false;
        for (let j = 0; j < newlist.length; j++) {
          if (oldlist[i].phoneId == newlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          relist.push(oldlist[i]);
        }
      }
      for (let i = 0; i < newlist.length; i++) {
        let flag = false;
        for (let j = 0; j < oldlist.length; j++) {
          if (newlist[i].phoneId == oldlist[j].phoneId) {
            flag = true;
            break;
          }
        }
        if (!flag) {
          inlist.push(newlist[i]);
        }
      }
      if (inlist.length) {
        finnallist = [...oldlist, ...inlist];
      }
      if (relist.length) {
        relist.forEach((ritem, rindex) => {
          oldlist.forEach((oitem, oindex) => {
            if (ritem.phoneId == oitem.phoneId) {
              oldlist.splice(oindex, 1);
            }
          });
        });
        finnallist = oldlist;
      }
      // console.log(finnallist);
      // console.log(relist);
      // console.log(inlist);
      return finnallist;
    },
    getgrouplist() {
      let data = {
        Action: "ListBusinessGroup",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        // CityId: "test03",
        ProductType: this.query.product_type,
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getgrouplist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.Infos);
          }
        });
      });
    },
    getphonelist() {
      let data = {
        Action: "DescribeUPhone",
        ProjectId: this.query.project_id,
        CityId: this.query.city_id,
        // CityId: "test03",
        IsAll: true,
        ProductType: this.query.product_type,
      };
      return new Promise((resolve, reject) => {
        this.$store.dispatch("group/getphonelist", { ...data }).then((res) => {
          if (res.RetCode == 0) {
            resolve(res.UPhones);
          }
        });
      });
    },
    gettreedata() {
      Promise.all([this.getgrouplist(), this.getphonelist()]).then(
        ([Groups, PhoneInfos]) => {
          const filterlist =
            Groups.length &&
            Groups.filter((item, index) => {
              item["children"] = [];
              PhoneInfos.map((eitem, eindex) => {
                eitem["label"] = eitem.UPhoneId;
                eitem["value"] = eitem.UPhoneId;
                eitem["phoneId"] = eitem.UPhoneId;
                eitem["uphoneName"] = eitem.UPhoneName;
                eitem["cityId"] = eitem.CityId;
                eitem["isLeaf"] = true;
                if (eitem.Tag == item.BusinessName) {
                  item["id"] = index;
                  item["label"] = item.BusinessName;
                  item["isLeaf"] = false;
                  item["children"].push(eitem);
                }
              });
              if (item.children.length !== 0) {
                return true;
              } else {
                return false;
              }
            });
          this.treedata = filterlist;
        }
      );
    },
  },
  mounted() {
    this.query = {
      project_id: getsession("project_id"),
      city_id: getsession("city_id"),
      product_type: getsession("product_type"),
    };
    // this.gettreedata();
  },
};
</script>
<style lang="scss" scoped>
.synchromodel {
  ::v-deep .el-dialog {
    width: 800px;
    height: 563px;
  }
  ::v-deep .el-dialog__header {
    padding: 0;
  }
  ::v-deep .el-dialog__body {
    height: 452px;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 10px 20px;
  }
  ::v-deep .el-dialog__footer {
    padding: 0;
  }
  ::v-deep .el-tabs__header {
    margin: 0;
  }
  ::v-deep .el-input {
    width: 300px;
  }
  .titlelabel {
    height: 54px;
    line-height: 54px;
    font-size: 13px;
    color: rgba(56, 56, 56);
  }
  .comtable {
    margin-top: 25px;
  }
}
</style>