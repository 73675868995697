<template>
  <div class="table-bar">
    <el-button
      @click="showuploadmodel"
      size="mini"
      type="primary"
      class="uploadbtn"
    >
      上传文件
    </el-button>

    <el-button id="download" @click="download" size="mini" type="primary" class="uploadbtn">
      下载视频转换工具
    </el-button>
    <el-button @click="renewlist" size="mini" type="primary" class="uploadbtn">
      刷新
    </el-button>
    <!-- <el-button class="more" type="primary" size="mini">...</el-button> -->
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    showuploadmodel() {
      this.$emit("showupload");
    },
    renewlist() {
      this.$emit("getufilelist");
    },
    download() {
      var url = "https://wumitech-app-market-gd.cn-gd.ufileos.com/wm-tool.zip";
      var form = document.createElement("form");
      document.getElementById("download").appendChild(form);
      form.action = url;
      form.submit();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>